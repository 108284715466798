export default class CartSessionService {
	static setcartIdInSession = async (args: { cartId: string }) => {
		const url = new URL(window.location.origin)
		url.pathname = "/api/cart-session"
		const body = new URLSearchParams()
		body.append("cartId", args.cartId)
		await fetch(url.href, {
			headers: {
				"Content-Type": "application/x-www-form-urlencoded"
			},
			method: "POST",
			body: body
		}).then((res) => {
			return res.json()
		})
	}
}
